<div class="topbar">
  <div class="mr-4">
    <app-topbar-fav-pages></app-topbar-fav-pages>
  </div>
  <div class="mr-auto d-none d-md-block">
    <app-topbar-search></app-topbar-search>
  </div>
  <div class="mr-4 d-none d-sm-block">
    <app-topbar-language-switcher></app-topbar-language-switcher>
  </div>
  <div>
    <app-topbar-user-menu></app-topbar-user-menu>
  </div>
</div>
